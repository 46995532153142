@import 'base/colors';
@import 'base/fonts';
@import 'base/media';
@import 'base/mixin';
@import 'base/fonts';
@import 'base/animate';

@import './base/export';

.fa-btn {
	border: 1px solid;
	@apply rounded-full py-1.5 px-4 text-sm font-normal
	 border-secondary border-opacity-10;
	.fa-icon {
		@apply w-5;
	}
}

.panel-loader-wrapper {
	@apply animate-fade-in;
}

.panel-loader {
	@apply flex-center overflow-hidden opacity-100 w-full;
	&.panel-loader-full-page {
		@apply flex-center;
		inset: 0;
		margin: 1rem 0 0 0 !important;
		padding: 0 !important;
		width: 100vw !important;
		height: 100vh !important;
		position: fixed !important;
		.loading-image {
			@apply mb-8;
		}
	}
}

.rs-panel-group .rs-panel + .rs-panel:before {
	@apply border-0;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
	box-shadow: none !important;
}

.hardware-label-icon {
	width: 20px;
	color: inherit;
	@apply mx-1.5 -mt-0.5;
}

//! Disabling the LastPass extension
div[data-lastpass-root],
div[data-lastpass-save],
div[data-lastpass-infield],
div[data-lastpass-icon-root] {
	@apply overflow-hidden;
	display: none !important;
}

.acc-modification-notify {
	@apply whitespace-nowrap;
	width: 370px !important;
}

.loading-image {
	@apply animate-spin;
	max-height: 85px;
	max-width: 85px;
}

.loader {
	width: 60px;
	aspect-ratio: 1;
	display: flex;
	animation: l8-0 2s infinite sptes(1);
}
.loader::before,
.loader::after {
	content: '';
	flex: 1;
	animation: l8-1 1s infinite linear alternate, l8-2 2s infinite steps(1) -0.5s;
}
.loader::after {
	--s: -1, -1;
}
@keyframes l8-0 {
	0% {
		transform: scaleX(1) rotate(0deg);
	}
	50% {
		transform: scaleX(-1) rotate(-90deg);
	}
}
@keyframes l8-1 {
	0%,
	5% {
		transform: scale(var(--s, 1)) translate(0px) perspective(150px) rotateY(0deg);
	}
	33% {
		transform: scale(var(--s, 1)) translate(-10px) perspective(150px) rotateX(0deg);
	}
	66% {
		transform: scale(var(--s, 1)) translate(-10px) perspective(150px) rotateX(-180deg);
	}
	95%,
	100% {
		transform: scale(var(--s, 1)) translate(0px) perspective(150px) rotateX(-180deg);
	}
}
@keyframes l8-2 {
	0% {
		background: $primary;
	}
	50% {
		background: $primary;
	}
}
