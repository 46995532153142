@import '../../Assets/scss/base/colors';
@import '../../Assets/scss/base/fonts';
@import '../../Assets/scss/base/media';
@import '../../Assets/scss/base/mixin';
@import '../../Assets/scss/base/fonts';
@import '../../Assets/scss/base/animate';

.loading-overlay-layout {
	z-index: 100;
	@apply flex-center fixed inset-0 bg-light pb-5;

	.center-box {
		@apply absolute;
		.logo {
			@apply -mt-4;
			width: 80px;
			height: 80px;
		}
	}

	.rs-loader {
		.rs-loader-spin,
		.rs-loader-spin::before,
		.rs-loader-spin::after {
			width: 180px;
			height: 180px;
		}

		.rs-loader-spin {
			&::after {
				//? Moving part
				border-top-color: rgba(#3e97b5, 0.5);
				border-top-width: 4px;
				box-shadow: 0 -15px 30px -12px rgba(0, 0, 0, 0.2);
			}
			&::before {
				//? Bg part
				@apply opacity-10;
				border-color: #3e97b5 !important;
			}
		}
	}
}
